import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Faq from "../../components/faq";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import OSSection from "../../components/other-services-section";
import OASection from "../../components/our-approach-section";
import ReviewSection from "../../components/review-section";
import blueOne from "../../images/1-blue.svg";
import blueTwo from "../../images/2-blue.svg";
import blueThree from "../../images/3-blue.svg";

const WebDesignPageDubai = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
        altText
        mediaDetails {
          height
          width
        }
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      img1: wpMediaItem(title: { eq: "design-latest" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }

      hiveImg: wpMediaItem(title: { eq: "Hivehr-fullpage" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }

      googleSearchImg: wpMediaItem(title: { eq: "google-search" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      wpSeoPage(title: { eq: "Web Design Dubai" }) {
        contentForHeadTag {
          content
        }
        seo {
          metaDesc
          title
          twitterImage {
            altText
            mediaDetails {
              height
              width
            }
            link
            gatsbyImage(
              width: 1920
              quality: 75
              formats: [WEBP]
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
          opengraphTitle
          opengraphDescription
        }
      }
      icon: wpMediaItem(title: { eq: "icon" }) {
        link
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const schema = data.wpSeoPage.contentForHeadTag.content;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Dubai",
        item: {
          url: `${siteUrl}/dubai`,
          id: `${siteUrl}/dubai`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Web Design",
        item: {
          url: `${siteUrl}/dubai/web-design`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        {" "}
        <script type="application/ld+json">{schema}</script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={data.wpSeoPage.seo.title}
        description={data.wpSeoPage.seo.metaDesc}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/dubai/web-design`,
          title: `${data.wpSeoPage.seo.opengraphTitle}`,
          description: `${data.wpSeoPage.seo.opengraphDescription}`,
          images: [
            {
              url: `${data.wpSeoPage.seo.twitterImage.link}`,
              width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
              height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
              alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
            },
          ],
        }}
      />

      <Layout dubai>
        <section>
          <Hero
            dubai
            button1="primary-link-dark"
            button1Text="Get in touch"
            button1Link="/dubai/contact-us"
            button1Var="white"
            button2={null}
            button3={null}
            backgroundImage={data.heroImg.gatsbyImage}
            title={`WEB DESIGN AGENCY \n<span class="text-secondary">DUBAI</span>`}
            description="Building your Dubai UAE business a bespoke website that generates you new leads and greater brand recognition."
          />
        </section>
        {/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#results"
								>
									Delivering results
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#pricing"
								>
									Pricing
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
        <section id="intro" className="py-5 bg-light-grey py-lg-7">
          <Container>
            <Row className="align-items-center gx-lg-6 gx-xl-7">
              <Col lg={6} className="mb-5 mb-lg-0">
                <h2 className="mb-5 display-5 text-primary">
                  Professional web design solutions that help you achieve more
                </h2>
                <div
                  className="mb-4 d-lg-none"
                  style={{ overflow: "hidden", borderRadius: "15px" }}
                >
                  <GatsbyImage
                    className="w-100"
                    image={data.img1.gatsbyImage}
                    alt={data.img1?.altText}
                  />
                </div>
                <p>
                  Imagine having a website for your Dubai business that not only
                  has a unique design but also effectively communicates your
                  brand with your target audience and generates you good quality
                  leads.
                </p>
                <p>
                  At RJM Digital, we specialise in creating custom websites using
                  the latest technology and Dubai web design principles in order
                  to help you achieve the results your business deserves. We are
                  a <strong>web design company in Dubai</strong>, UAE that is committed to seeing
                  you <strong>succeed online</strong> through the use of our web design services
                  and search engine optimisation techniques.
                </p>
                <p>
                  Throughout the entire website design and development process,
                  we value your input from the first brief to the final launch.
                  This enables us to understand your company in Dubai, including
                  your needs and requirements in more depth in order to build you
                  a new website that perfectly represents your brand. With our
                  tried-and-tested four-step approach to web design we can bring
                  your <strong>dream website to life</strong>.
                </p>
                <p>
                  Our <strong>friendly</strong> and <strong>professional</strong> support 
                  team will always be on hand to monitor your website and
                  make any changes or updates when necessary, so you can have peace
                  of mind knowing your website is being taken care of and is in safe
                  hands with RJM Digital.
                </p>
                <Button
                  className="px-4 py-2 mt-4 cta-btn w-100 w-md-auto fs-5 white-link-yellow"
                  variant="primary"
                  as={Link}
                  to="/dubai/contact-us"
                >
                  Get in touch
                </Button>
              </Col>
              <Col className="d-none d-lg-block" lg={6}>
                <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                  <GatsbyImage
                    className="w-100"
                    image={data.img1.gatsbyImage}
                    alt={data.img1?.altText}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="results" className="py-5 py-lg-7 bg-dark-grey">
          <Container className="mb-8">
            <Row className="justify-content-between gx-5">
              <Col lg={5} className="pb-5 position-relative d-none d-lg-block">
                <div
                  style={{ borderRadius: " 15px", overflow: "hidden" }}
                  className="mt-4 bg-white ms-auto w-100"
                >
                  <GatsbyImage
                    className="w-100 "
                    image={data.googleSearchImg.gatsbyImage}
                    alt={data.googleSearchImg?.altText}
                  />
                </div>
              </Col>
              <Col lg={7}>
                <h2 className="mb-5 text-white display-4 ssp-bold text-uppercase">
                  Are you looking for the best{" "}
                  <span className="text-secondary">web design</span> agency in{" "}
                  <span className="text-secondary">Dubai</span>?
                </h2>
                <p className="text-white">
                  At RJM Digital, we go beyond creating visually appealing websites. Our focus
                  is on building your site to perfectly match the specific requirements of your
                  company in Dubai. By utilising the <strong>latest technology</strong>, our experienced team of
                  web developers will ensure that your website stands out from the competition
                  in your industry. We also provide full analytics monitoring of your agency
                  designed site, giving you the confidence that your website is delivering the
                  results that it should.
                </p>
                <Button
                  className="px-4 py-2 mt-4 cta-btn w-100 w-md-auto fs-5 primary-link-dark"
                  variant="white"
                  href="#form"
                >
                  Book a discovery call
                </Button>
              </Col>
            </Row>
          </Container>
        </section>

        <div id="approach">
          <OASection
            heading="OUR 4-STEP APPROACH"
            dubai
            imgHeight="60rem"
            image={data.hiveImg.gatsbyImage}
            imageAlt={data.hiveImg?.altText}
            launch="After the RJM team ensures that everything is in order and you're ready to go, we can launch your website. Our friendly support team will then be available to you to resolve any issues that may arise and to make any future changes to your site as and when you need to."
            build="RJM Digital uses the most up-to-date technology to make sure your Dubai businesses new website is quick and accessible on any device. We will test your site thoroughly to ensure your users will have a great user experience and it is fully prepped for SEO, so that it can be found on search engines."
            design="Once we have understood your requirements for the design, we will now begin crafting ideas for your website. We will engage with you throughout this stage and keep you up-to-date with our progress with regular check-in sessions to make any necessary changes until you are completely satisfied with the final result."
            brief="Our expert web design Dubai team will make building or redesigning your website seem like a walk in the park. We will guide you through the process and ensure that your website not only represents your brand but also acts as an important asset in your marketing campaigns."
          />
        </div>
        <section id="benefits" className="py-5 p-lg-7 bg-dark-grey">
          <Container className="">
            <Row>
              <Col>
                <h2 className="mb-5 text-white display-4 ssp-bold text-uppercase">
                  Why your <span className="text-light-blue">Dubai</span>{" "}
                  business should invest in a{" "}
                  <span className="text-light-blue">new website</span>
                </h2>
              </Col>
            </Row>
            <Row className=" g-6 g-lg-4 justify-content-center">
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="text-center position-absolute w-100 h-100"
                >
                  <img src={blueOne} className="w-md-40 w-30" />
                </div>
                <div className="pt-5 px-xl-5 position-relative">
                  <h3 className="text-white fs-1">Stand out from the crowd</h3>
                  <p className="text-white">
                    A professionally designed and developed website by a web design agency in Dubai will allow
                    your business to stand out from competitors in Dubai and beyond. Your new website will
                    incorporate advanced features such as super fast loading speeds that will help to differentiate
                    your business in the market.
                  </p>
                </div>
              </Col>
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="text-center position-absolute w-100 h-100"
                >
                  <img src={blueTwo} className="w-md-40 w-30" />
                </div>
                <div className="pt-5 px-xl-5 position-relative">
                  <h3 className="text-white fs-1">More Visibility</h3>
                  <p className="text-white">
                    By using the best search engine optimisation (SEO)
                    techniques, your new website will gain greater visibility in
                    search engine results pages. Therefore helping to drive
                    organic traffic to your website.
                  </p>
                </div>
              </Col>
              <Col className="position-relative " md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="text-center position-absolute w-100 h-100"
                >
                  <img src={blueThree} className="w-md-40 w-30" />
                </div>
                <div className="pt-5 px-xl-5 position-relative">
                  <h3 className="text-white fs-1">Mobile-Friendly Design</h3>
                  <p className="text-white">
                    With more users accessing the internet through mobile
                    devices, having a website optimised for mobile is essential.
                    We will design your website to be responsive and
                    mobile-friendly, ensuring a seamless user experience across
                    various devices.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <div id="pricing">
					<PricingSection
						customText="Have a custom website project for your Dubai business?"
						text1="For small UAE businesses and start-ups looking for their first website"
						text2="For ambitious UAE companies looking to increase the amount of leads to their website"
						text3="For established UAE businesses requiring an advanced solution"
						dubai
						mEssPrice="AED4995"
						mPrePrice="AED6500"
						mProPrice="AED10000"
						mPmEssPrice="AED495"
						mPmPrePrice="AED695"
						mPmProPrice="AED995"
						oEssPrice="AED14995"
						oPrePrice="AED19995"
						oProPrice="AED29995"
						oCpEssPrice="AED300"
						oCpPrePrice="AED300"
						oCpProPrice="AED300"
					/>
				</div> */}

        <section className="pt-5 pt-lg-7 bg-light-grey">
          <Container>
            <Row id="form">
              <Col className="text-center">
                {" "}
                <h2 className="mb-3 display-5 text-primary">
                  Book a discovery meeting
                </h2>
                <p>
                  Book a time and day that works for you through our online
                  calendar to discuss your project.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="d-xl-none">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "1150px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
                />
              </Col>
              <Col className="d-none d-xl-block">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "700px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
                />
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
          <ReviewSection page="Our Web Services" />
        </section>
        <div id="faq">
          <Faq page="Web Design" />
        </div>
        <OSSection
          dubai
          link1="/dubai/seo"
          link3="/dubai/sustainable-web-design"
          text3="SUSTAINABLE WEBSITES"
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1?.altText}
        />
      </Layout>
    </>
  );
};

export default WebDesignPageDubai;
